import React, { useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'

const CcIcons = ({displayCardsOnly = false, className=''}) => {

  // script being writen everytime component loads, prevent
  useEffect(() => {
    if(!displayCardsOnly) {
      let wrapperEl = document.getElementById("authorize-net-seal")
      const authScript = document.createElement('script')
      authScript.type = 'text/javascript'
      authScript.src = "/auth.min.js"
      authScript.async = true
      wrapperEl.appendChild(authScript)
    }
  }, [])

  return (
    <aside className={`cc-icons ${className}`}>
      <i className="fab fa-cc-visa"></i>
      <i className="fab fa-cc-mastercard"></i>
      <i className="fab fa-cc-amex"></i>
      <i className="fab fa-cc-discover"></i>
      <i className="fab fa-cc-diners-club"></i>
      <i className="fab fa-cc-jcb"></i>
      { !displayCardsOnly && (
        <Fragment>
          <div className="AuthorizeNetSeal" id="authorize-net-seal"></div>
          <div className="trustedsite-trustmark" data-type="202" data-width="120" data-height="50"></div>
          <div className="trustedsite-trustmark" data-type="212" data-width="120" data-height="50"></div>
        </Fragment>
      )}
    </aside>
  )
}

CcIcons.propTypes = {
  displayCardsOnly: PropTypes.bool,
  className: PropTypes.string
}

export default CcIcons
