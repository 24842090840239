import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types'

import { earliestStartDate } from '../helpers/general'

const initialState = {
  destinations: {},
  destinationsAll: {},
  destinationsDropdownList: {},
  featuredDestinations: [],
  ticketDestinations: [],
  activeDestination: "",
  activeDestinationHeading: "",
  activeDestinationTitle: "",
  activeDestinationNav: "",
  activeFeaturedDestination: "",
  playing: undefined,
  animationCount: 0,
  dropdown: {
    destination: {
      open: false,
      selected: "",
      hovered: null,
    }
  },
  spinnerActive: true,
  nights: 3,
  arrival: earliestStartDate()
}

export const HERO = {
  DESTINATIONS: {
    SET: 'HERO/DESTINATIONS/SET',
    FEATURED: 'HERO/DESTINATIONS/FEATURED',
    ALL: 'HERO/DESTINATIONS/ALL',
    DROPDOWN: 'HERO/DESTINATIONS/DROPDOWN'
  },
  ACTIVE_DESTINATION: {
    SET: 'HERO/ACTIVE_DESTINATION/SET',
    HEADING: 'HERO/ACTIVE_DESTINATION/HEADING',
    TITLE: 'HERO/ACTIVE_DESTINATION/TITLE',
    NAV: 'HERO/ACTIVE_DESTINATION/NAV',
    FEATURED: 'HERO/ACTIVE_DESTINATION/FEATURED'
  },
  TICKET_DESTINATIONS: {
    SET: 'HERO/TICKET_DESTINATIONS/SET'
  },
  PLAYING: {
    SET: 'HERO/PLAYING/SET'
  },
  ANIMATION_COUNT: {
    SET: 'HERO/ANIMATION_COUNT/SET'
  },
  DROPDOWN: {
    SET: 'HERO/DROPDOWN/SET'
  },
  SPINNER_ACTIVE: {
    SET: 'HERO/SPINNER_ACTIVE/SET'
  },
  NIGHTS: {
    SET: 'HERO/NIGHTS/SET'
  },
  ARRIVAL: {
    SET: 'HERO/ARRIVAL/SET'
  }
}

let reducer = ( state, action ) => {
  switch(action.type) {
    case "reset":
      return initialState
    case HERO.DESTINATIONS.SET:
      return { ...state, destinations: action.payload }
    case HERO.DESTINATIONS.ALL:
      return { ...state, destinationsAll: action.payload }
    case HERO.DESTINATIONS.DROPDOWN:
      return { ...state, destinationsDropdownList: action.payload }
    case HERO.DESTINATIONS.FEATURED:
      return { ...state, featuredDestinations: action.payload }
    case HERO.TICKET_DESTINATIONS.SET:
      return { ...state, ticketDestinations: action.payload }
    case HERO.ACTIVE_DESTINATION.SET:
      return { ...state, activeDestination: action.payload }
    case HERO.ACTIVE_DESTINATION.TITLE:
      return { ...state, activeDestinationTitle: action.payload  }
    case HERO.ACTIVE_DESTINATION.HEADING:
      return { ...state, activeDestinationHeading: action.payload }
    case HERO.ACTIVE_DESTINATION.NAV:
      return { ...state, activeDestinationNav: action.payload }
    case HERO.ACTIVE_DESTINATION.FEATURED:
      return { ...state, activeFeaturedDestination: action.payload }
    case HERO.PLAYING.SET:
      return { ...state, playing: action.payload }
    case HERO.ANIMATION_COUNT.SET:
      return { ...state, animationCount: action.payload }
    case HERO.DROPDOWN.SET:
      return { ...state, dropdown: action.payload }
    case HERO.SPINNER_ACTIVE.SET:
      return { ...state, spinnerActive: action.payload }
    case HERO.NIGHTS.SET:
      return { ...state, nights: action.payload }
    case HERO.ARRIVAL.SET:
      return { ...state, arrival: action.payload }
    default:
      return state
  }
}

export const HeroContext = createContext()

export const HeroProvider = ({ children }) => {
  let [state, dispatch] = useReducer(reducer, initialState)
  let value = { state, dispatch }

  return (
    <HeroContext.Provider value={ value }>
      { children }
    </HeroContext.Provider>
  )
}

HeroProvider.propTypes = {
  children: PropTypes.node
}

export const HeroConsumer = HeroContext.Consumer
