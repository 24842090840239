import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const displayRandomText = (text) => <span
  className="rotate-text"
  dangerouslySetInnerHTML={{ __html: text }} />

const randomize = textArray => textArray[Math.floor(Math.random() * textArray.length)]

const RotateRandomizedText = ({textArray}) => {
  const [ text, setText ] = useState(randomize(textArray))
  let timeout

  useEffect(
    () => {
      timeout = setTimeout(newText, 8000)
      return () => {
        clearTimeout(timeout)
      }
    }, []
  )

  function newText() {
    setText(randomize(textArray))
    timeout = setTimeout(newText, 8000)
  }

  return displayRandomText(text)
}

RotateRandomizedText.propTypes = {
  textArray: PropTypes.array.isRequired
}

export default RotateRandomizedText
