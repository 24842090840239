import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import DestinationList from './DestinationList'
import useSetDestinationListBarPos from '../hooks/useSetDestinationListBarPos'

import { HERO, HeroContext } from '../../contexts/HeroContext'

const DestinationsFeaturedList = ({
  selectDropdownOption,
  listRef
}) => {

  const { state: {
    activeDestination,
    activeDestinationNav,
    dropdown,
    playing,
    spinnerActive
  }, dispatch } = useContext(HeroContext)
  const { setListBarSelection } = useSetDestinationListBarPos()
  const _setPlaying = (playing) =>
    dispatch({ type: HERO.PLAYING.SET, payload: playing })
  const _setDropdown = (dropdown) =>
    dispatch({ type: HERO.DROPDOWN.SET, payload: dropdown })
  const _setActiveDestinationNav = (nav) =>
    dispatch({ type: HERO.ACTIVE_DESTINATION.NAV, payload: nav })

  useEffect(() => {
    // Set initial position for dest list
    setListBarSelection(activeDestination)
  }, [])

  useEffect(
    () => {
      if (getActiveDropdown() === 'destination') {
        setListBarSelection(activeDestination)
      }
    },
    [activeDestination]
  )

  const getActiveDropdown = () => dropdown.destination.open ? 'destination' : null

  const togglePlaying = (callback = null) => {
    _setPlaying(!playing)
    callback(!playing)
  }

  const _playPause = () => {
    const activeDropdown = getActiveDropdown()

    togglePlaying((value) => {
      if (value && activeDropdown) {
        const dropdownTmp = dropdown
        dropdownTmp[activeDropdown].open = false
        dropdownTmp[activeDropdown].hovered = false
        _setDropdown(dropdownTmp)
      }
    })
  }

  return (
    <div
      className="row destination-list-nav container"
      ref={listRef}>
      <div className="list-nav-heading">
        <div
          className={`destination-spinner circle-spinner ${
            spinnerActive ? 'active' : ''
          }`}
          onClick={() => _playPause()}
        >
          <svg width="20" height="20" className="animated-circle">
            <circle
              className=""
              cx="10"
              cy="10"
              r="9"
              transform="rotate(-90, 10, 10)"
            />
          </svg>
          <i className="fas fa-play" aria-hidden="true" />
          <i className="fas fa-pause" aria-hidden="true" />
        </div>
        <h3>Featured Destinations</h3>
      </div>
      <div className="destination-list-bar-container">
        <div className="destination-list-bar" />
      </div>
      <DestinationList
        active={activeDestinationNav}
        selectDropdownOption={selectDropdownOption}
        setActiveDestinationNavElement={_setActiveDestinationNav}
      />
    </div>
  )
}

DestinationsFeaturedList.propTypes = {
  selectDropdownOption: PropTypes.func.isRequired,
  listRef: PropTypes.object.isRequired
}

export default DestinationsFeaturedList
