import React, { useState, useEffect, useContext, useRef, Suspense, lazy } from 'react'
import PropType from 'prop-types'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { isMobileOnly } from 'react-device-detect'

import { HERO, HeroContext } from '../contexts/HeroContext'

import Loading from '../common/Loading'
import DestinationsFeaturedList from './featured/DestinationsFeaturedList'
import useSetDestinationListBarPos from './hooks/useSetDestinationListBarPos'
import useSetBackgroundImage from './hooks/useSetBackgroundImage'

const TabsPackageDeal = lazy(() => import('./tabs/packages/TabsPackageDeal'))
const TabsTickets = lazy(() => import('./tabs/tickets/TabsTickets'))

import {
  hidePlaceholder
} from '../helpers/heroHelper'
import { addIEClassToBody } from '../../common/helpers/general'

import { setDelay } from '../helpers/general'

const DESINATION_LOOP_INTERVAL = 10000

const DestinationsHero = (props) => {
  const { state, dispatch } = useContext(HeroContext)
  const _setActiveDestinationTitle = (title) =>
    dispatch({ type: HERO.ACTIVE_DESTINATION.TITLE, payload: title })
  const _setActiveDesination = (active) =>
    dispatch({ type: HERO.ACTIVE_DESTINATION.SET, payload: active })
  const _setActiveFeaturedDesination = (active) =>
    dispatch({ type: HERO.ACTIVE_DESTINATION.FEATURED, payload: active })
  const _setActiveDestinationHeading = (heading) =>
    dispatch({ type: HERO.ACTIVE_DESTINATION.HEADING, payload: heading })
  const _setActiveDestinationNav = (nav) =>
    dispatch({ type: HERO.ACTIVE_DESTINATION.NAV, payload: nav })
  const _setDropdown = (dropdown) =>
    dispatch({ type: HERO.DROPDOWN.SET, payload: dropdown })
  const _setPlaying = (playing) =>
    dispatch({ type: HERO.PLAYING.SET, payload: playing })
  const _setNights = (nights) =>
    dispatch({ type: HERO.NIGHTS.SET, payload: nights })
  const _setAnimationCount = (count) =>
    dispatch({ type: HERO.ANIMATION_COUNT.SET, payload: count })
  const _setSpinnerActive = (active) =>
    dispatch({ type: HERO.SPINNER_ACTIVE.SET, payload: active })
  const [height, setHeight] = useState('100vh')

  const { destinations, destinationsAll, lowestStay, lowestNightsList, ticketDestinations } = props
  const orgMinNights = lowestNightsList ? lowestNightsList[destinations[0].slug] : lowestStay
  const [minNights, setMinNights] = useState(orgMinNights)
  const [webPSupport, setWebPSupport] = useState(true)

  const { setListBarSelection } = useSetDestinationListBarPos()
  const { setBackgroundImage }  = useSetBackgroundImage()
  const destinationListRef      = useRef()
  const containerRef            = useRef()

  useEffect(
    () => {
      _detectWebPSupport()
      _setInnerHeight()
      _setDestinationsFromProps()
      _setDestinationsAllFromProps()
      _setTicketDestinationsfromProps()
      window.addEventListener('resize', _setInnerHeight)
      addIEClassToBody()
      hidePlaceholder()
      // keyboard listener for esc
      document.addEventListener('keydown', _keyListener)
      _setPlaying(true)

      return () => {
        window.removeEventListener('resize', _setInnerHeight)
        document.removeEventListener('keydown', _keyListener)
      }
    },[]
  )

  const _detectWebPSupport = () => {
    var image = new Image()
    const imageResult = (event) => {
      var result = event && event.type === 'load' ? image.width === 1 : false;
      setWebPSupport(result)
    }
    image.onload = imageResult
    image.onerror = imageResult
    image.src = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=';
  }

  function _setInnerHeight() {
    setHeight(window.innerHeight + 'px')
    setTimeout(_setBottomMargin, 600)
  }

  const _setBottomMargin = () => {
    if(destinationListRef) {
      let listHeight = destinationListRef.current.clientHeight
      let newHeight  = !isMobileOnly ? listHeight + 24 : listHeight
      if(containerRef) containerRef.current.style.marginBottom = `${newHeight}px`
    }
  }

  function _keyListener(e) {
    if (e.which === 27) toggleOffDropdowns()
  }

  const _normalizeDesinations = (destinations) => {
    return destinations.reduce((obj, destination) => {
      return {
        ...obj,
        [destination['slug']]: {
          'title': destination['title'],
          'city': destination['city'],
          'state': destination['state'],
          'image': destination['hero_bg_image'],
          'webp_image': destination['hero_bg_image_webp'],
          'verb': destination['hero_verb'],
          'featured': destination['hero_featured']
        }
      }
    }, {})
  }

  const _setDestinationsFromProps = () => {
    if(destinations) {
      let normalizedDestinations = _normalizeDesinations(destinations),
          slugs     = Object.keys(normalizedDestinations),
          firstDest = normalizedDestinations[slugs[0]],
          firstSlug = slugs[0]

      dispatch({ type: HERO.DESTINATIONS.SET, payload: normalizedDestinations })
      dispatch({ type: HERO.DESTINATIONS.FEATURED, payload: slugs })
      dispatch({ type: HERO.ACTIVE_DESTINATION.SET, payload: firstSlug })
      dispatch({ type: HERO.ACTIVE_DESTINATION.FEATURED, payload: firstSlug })
      _setActiveDestinationHeading(firstDest.verb)
      _setActiveDestinationTitle(firstDest.title)
      _setActiveDestinationNav(firstSlug)
      if(minNights) _setNights(minNights) // Set initial nights
      _setPlaying(true)
      setBackgroundImage({url: webPSupport ? firstDest.webp_image : firstDest.image, playing: true})
      setListBarSelection(firstSlug)
    }
  }

  const _setDestinationsAllFromProps = () => {
    if(destinationsAll) {
      let normalized = _normalizeDesinations(destinationsAll)//,
          //slugs      = Object.keys(normalized)

      let dropDownEntries = []
      for (const [slug, dest] of Object.entries(normalized)) {
        dropDownEntries.push({value: slug, label: dest.title})
      }

      dispatch({ type: HERO.DESTINATIONS.ALL, payload: normalized })
      dispatch({ type: HERO.DESTINATIONS.DROPDOWN, payload: dropDownEntries })

      //dispatch({ type: HERO.DESTINATIONS.ALL, payload: normalized })
      //dispatch({ type: HERO.DESTINATIONS.DROPDOWN, payload: slugs })
    }
  }

  const _setTicketDestinationsfromProps = () => {
    if (ticketDestinations) {
      let ticketPayload = ticketDestinations.map((dest) => {
        return { value: dest, label: dest }
      })
      dispatch({type: HERO.TICKET_DESTINATIONS.SET, payload: ticketPayload })
    }
  }

  const setDropdownContent = (value) => {
    if (value) {
      _setActiveDestinationHeading(
        getDestinationData(value, 'verb')
      )
    }
  }

  const getDestinationData = (destination, property) => {
    switch (property) {
      case 'verb':
        return state.destinations[destination].verb
      case 'image':
        if (webPSupport) {
          return state.destinations[destination].webp_image
        }
        else {
          return state.destinations[destination].image
        }
      default:
        break
    }
  }

  const toggleOffDropdowns = () => {
    const dropdownTmp = {...state.dropdown}
    Object.keys(dropdownTmp).forEach((item) => {
      dropdownTmp[item].open = false
      dropdownTmp[item].hovered = null
    })
    _setDropdown(dropdownTmp)
  }

  const selectDropdownOption = (menu, option, animated) => {
    const dropdownTmp = {...state.dropdown}
    dropdownTmp[menu].open = false

    if (dropdownTmp[menu].selected !== option) {
      dropdownTmp[menu].selected = option
      dropdownTmp[menu].hovered  = null

      if (menu === 'destination') {
        setActiveDestination(option)

        if(lowestNightsList) {
          _setNights(lowestNightsList[option])
          setMinNights(lowestNightsList[option])
        }

        if (!animated) {
          let $heroBg = document.querySelector('.hero-bg')
          $heroBg.style.transform = 'scale(1)'
          $heroBg.style.animation = 'none'
        }

        if (state.playing && !animated) {
          clearInterval(window._destinationInterval_)
          _setPlaying(false)
        }
      }
    }
    _setDropdown(dropdownTmp)
  }

  const initSpinner = () => _setSpinnerActive(true)

  // Stop playing if animation count = destination list
  const checkAnimationCount = (count) => {
    let check = count >= Object.keys(state.destinations).length - 1

    if (check) _setPlaying(false)
    return check
  }

  const clearDestinationLoopInterval = () => {
    clearInterval(window._destinationInterval_)
    window._destinationInterval_ = null
    _setAnimationCount(0)
  }

  const getDropdownState = () => state.dropdown.destination.open

  const _setActiveFeaturedDestination = (value) => {
    _setActiveFeaturedDesination(value)
    _setActiveDestinationNav(value)
    setListBarSelection(value)
    setBackgroundImage({url: getDestinationData(value, 'image'), playing: state.playing})
  }

  const setDestinationLoop = (interval) => {
    // Any function inside a component “sees” the
    // props and state from the render it was created in
    // not the latest, need to keep interal track of values
    // Have to update state and local
    let count = 0
    let activeDestination = state.activeFeaturedDestination
    let dropdownList = state.featuredDestinations

    if (window._destinationInterval_) {
      clearInterval(window._destinationInterval_)
      _setAnimationCount(0)
      count = 0
    }

    // Inital spinner animation( 1000ms shorter)
    const duration = DESINATION_LOOP_INTERVAL
    // TODO: useRef here
    const $circle = document.querySelector('.destination-spinner circle')

    if (count == 0)
      $circle.style.animation = `show100 ${duration}ms linear infinite`

    window._destinationInterval_ = setInterval(() => {
      if (checkAnimationCount(count)) return
      count = count + 1
      _setAnimationCount(count)

      initSpinner()
      $circle.style.animation = ''

      if (!getDropdownState()) {

        const indexOfActive = dropdownList.indexOf(
          activeDestination
        )
        const indexOfNext = dropdownList[indexOfActive + 1]
          ? indexOfActive + 1
          : 0
        const current = dropdownList[indexOfActive]
        const next = dropdownList[indexOfNext]
        _setActiveFeaturedDestination(current)

        setDelay(500)
          .then(() => {
            _setActiveFeaturedDestination(next)
            return setDelay(500)
          })
          .then(() => {
            activeDestination = next
          })
      }
    }, interval)
  }

  // Play/Pause actions
  useEffect(
    () => {
      if (state.playing !== undefined) {
        if (state.playing) {
          setDestinationLoop(DESINATION_LOOP_INTERVAL)
          initSpinner()
          const heroBG = document.querySelector('.hero-bg')
          if(heroBG) heroBG.style.animationPlayState = 'running'
        } else {
          clearDestinationLoopInterval()
          _setSpinnerActive(false)
          // remove inital animation if exists
          let spinnerEl = document.querySelector('.destination-spinner circle')
          if(spinnerEl) spinnerEl.style.animation = ''

          const heroBG = document.querySelector('.hero-bg')
          if(heroBG) heroBG.style.animationPlayState = 'paused'
        }
      }
    },
    [state.playing]
  )

  const setActiveDestination = (value) => {
    _setActiveDesination(value)
    _setActiveDestinationNav(value)
    setDropdownContent(value)
    setListBarSelection(value)
    setBackgroundImage({url: getDestinationData(value, 'image'), playing: state.playing})
  }

  const _handleTabSelection = (index, lastIndex) => {
    if(index == 1 && lastIndex !== 1) { //tickets tab
      if(state.playing) _setPlaying(false)
    }
  }

  return (
    <section id="hero" className="hero" style={{ height: height }}>
      <div className="hero-bg" />
      <div className="hero-bg-gradient" />

      <div
        className="container hero-content-container"
        ref={containerRef}>
        <Tabs
          onSelect={_handleTabSelection}
          >
          <TabList>
            <Tab>
              <i className="fas fa-umbrella-beach" />
              <span>Package Deals</span>
            </Tab>
            <Tab>
              <i className="fas fa-ticket-alt" />
              <span>Tickets</span>
            </Tab>
          </TabList>

          <TabPanel>
            <Suspense fallback={<Loading />}>
              <TabsPackageDeal
                minNights         = {minNights}
                setMinNights      = {setMinNights}
                lowestNightsList  = {lowestNightsList}
                lowestStay        = {lowestStay}
                webPSupport       = {webPSupport}
                />
            </Suspense>
          </TabPanel>
          <TabPanel>
            <Suspense fallback={<Loading />}>
              <TabsTickets />
            </Suspense>
          </TabPanel>
        </Tabs>
      </div>

      <DestinationsFeaturedList
        selectDropdownOption  = { selectDropdownOption }
        listRef               = { destinationListRef }
        />

    </section>
  )
}

DestinationsHero.propTypes = {
  destinations: PropType.array.isRequired,
  destinationsAll: PropType.array.isRequired,
  lowestStay: PropType.number.isRequired,
  lowestNightsList: PropType.object.isRequired,
  ticketDestinations: PropType.array.isRequired,
}

export default DestinationsHero
