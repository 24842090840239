import { useEffect, useState } from 'react'
import { setDelay } from '../../helpers/general'

const useSetBackgroundImage = () => {
  const [backgroundImage, setBackgroundImage] = useState(null)
  const BACKGROUND_ANIMATION_DUATION          = 150

  useEffect(
    () => {

      if (!backgroundImage) return

      const $hero   = document.querySelector('.hero')
      const $heroBG = $hero.querySelector('.hero-bg')

      $heroBG.style.opacity     = 1
      $heroBG.style.transition  = `opacity ${BACKGROUND_ANIMATION_DUATION}ms linear`

      const image = `
        linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.6) 0%,
          rgba(0, 0, 0, 0.3) 70%,
          rgba(0, 0, 0, 0.9) 100%
          ),
          url(${backgroundImage.url})
      `
      const $clonedBG = $heroBG.cloneNode(true)
      $clonedBG.style.backgroundImage = image

      if (!backgroundImage.playing) {
        $clonedBG.style.animationPlayState = 'paused'
      } else {
        $clonedBG.style.animationPlayState = 'running'
      }

      $hero.insertBefore($clonedBG, $hero.firstChild)
      $heroBG.style.opacity = 0

      setDelay(BACKGROUND_ANIMATION_DUATION).then(() => {
        let el = document.querySelectorAll('.hero-bg')
        $hero.removeChild(el[1])
      })
    }, [backgroundImage]
  )

  return { setBackgroundImage }
}

export default useSetBackgroundImage