import React from "react"
import PropTypes from 'prop-types'

const Loading = ({
  style = {},
  height = "100%",
  width = "100%",
  className = "loading",
  viewBox = "0 0 100 100"
}) => (
  <svg
    height={height}
    width={width}
    style={style}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    preserveAspectRatio="xMidYMid"
  >
    <circle
      cx="50" cy="50"
      r="5.39606" fill="none"
      ng-attr-stroke="{{config.c1}}"
      ng-attr-stroke-width="{{config.width}}"
      stroke="#faa42f" strokeWidth="2">
      <animate
        attributeName="r" calcMode="spline"
        values="0;40" keyTimes="0;1"
        dur="1.2" keySplines="0 0.2 0.8 1"
        begin="-0.6s" repeatCount="indefinite"></animate>
      <animate
        attributeName="opacity" calcMode="spline"
        values="1;0" keyTimes="0;1"
        dur="1.2" keySplines="0.2 0 0.8 1"
        begin="-0.6s" repeatCount="indefinite"></animate>
    </circle>
    <circle
      cx="50" cy="50"
      r="27.9678" fill="none"
      ng-attr-stroke="{{config.c2}}"
      ng-attr-stroke-width="{{config.width}}"
      stroke="#39a2e4" strokeWidth="2">
      <animate
        attributeName="r" calcMode="spline"
        values="0;40" keyTimes="0;1"
        dur="1.2" keySplines="0 0.2 0.8 1"
        begin="0s" repeatCount="indefinite"></animate>
      <animate
        attributeName="opacity" calcMode="spline"
        values="1;0" keyTimes="0;1"
        dur="1.2" keySplines="0.2 0 0.8 1"
        begin="0s" repeatCount="indefinite"></animate>
    </circle>
  </svg>
)

Loading.propTypes = {
  style: PropTypes.object,
  height: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
  viewBox: PropTypes.string
}

export default Loading
