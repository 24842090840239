import { useEffect, useState } from 'react'

const getActiveDestinationNav = (selection) => {
  return document.querySelector(
    `.destination-list [data-item='${selection}']`
  )
}

// Set Position of the bar above destinations list
const useSetDestinationListBarPos = (destSelection) => {

  const [destinationSelection, setListBarSelection] = useState(destSelection)
  let $listBarContainer = document.querySelector(
    '.hero .destination-list-bar-container'
  )

  useEffect(
    () => {
      if($listBarContainer &&
        destinationSelection) {
        const $listBar = document.querySelector('.destination-list-bar')
        const $activeDestListItem = getActiveDestinationNav(destinationSelection)
        if($activeDestListItem) {
          const width = $activeDestListItem.clientWidth
          const xPos = $activeDestListItem.offsetLeft - $listBarContainer.offsetLeft
          $listBar.style.width      = width + 'px'
          $listBar.style.transform  = `translateX(${xPos}px)`
        }
      }
    }, [destinationSelection]
  )

  return { setListBarSelection }
}

export default useSetDestinationListBarPos