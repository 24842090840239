import React, { useContext } from "react"
import PropTypes from "prop-types"

import { HeroContext } from '../../contexts/HeroContext'

const DestinationList = ({
  active,
  selectDropdownOption,
  setActiveDestinationNavElement
}) => {

  const { state: { destinations } } = useContext(HeroContext)

  const _handleOnClick = (data) => {
    selectDropdownOption("destination", data)
    setActiveDestinationNavElement(data)
  }

  const outputItems = () => {
    return Object.keys(destinations).map((dest, i) => {
      let item = destinations[dest]
      return (
        <div
          className={`dest-list-item ${active === dest ? "active" : ""}`}
          key={i}
          data-item={ dest }
          onClick= { () => _handleOnClick(dest)}
          >
          <i className="fas fa-circle" aria-hidden="true"></i>
          <div className="item-heading">{ item.city }</div>
          <div className="item-sub-heading">{ item.state }</div>
        </div>
      )
    })

  }

  const _handleArrowClick = () => {
    const $packagesSection = document.querySelector("section.packages")
    if($packagesSection) {
      $packagesSection.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      })
    }

    // $ga.event("Hero", "Destination List Arrow", "Scroll Down")
  }

  return (
    <div className="destination-list">
      { outputItems() }
      <div className="dest-list-arrows" onClick={ () => _handleArrowClick() }>
        <i className="fas fa-angle-down" aria-hidden="true"></i>
        <i className="fas fa-angle-down" aria-hidden="true"></i>
      </div>
    </div>
  )
}

DestinationList.propTypes = {
  active: PropTypes.string,
  selectDropdownOption: PropTypes.func,
  setActiveDestinationNavElement: PropTypes.func
}

export default DestinationList
