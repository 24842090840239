import React from "react"
import PropTypes from 'prop-types'
import {default as LoadingIcon} from './icons/Loading'

const Loading = ({className}) => <div className={className}><LoadingIcon className={className}/></div>

Loading.propTypes = {
  className: PropTypes.string
}

Loading.defaultProps = {
  className: 'loading'
}

export default Loading
