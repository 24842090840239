import React from 'react'
import PropTypes from 'prop-types'
import { HeroProvider } from '../contexts/HeroContext'
import DestinationsHero from './DestinationsHero'

const HomepageHero = ({ lowestStay, nightsList, destinations, destinationsAll, ticketDestinations }) => (
  <HeroProvider>
    <DestinationsHero
      lowestStay={lowestStay}
      lowestNightsList={nightsList}
      destinations={destinations}
      destinationsAll={destinationsAll}
      ticketDestinations={ticketDestinations}
      />
  </HeroProvider>
)

HomepageHero.propTypes = {
  lowestStay: PropTypes.number.isRequired,
  nightsList: PropTypes.object.isRequired,
  destinations: PropTypes.array.isRequired,
  destinationsAll: PropTypes.array.isRequired,
  ticketDestinations: PropTypes.array.isRequired,
}

export default HomepageHero
