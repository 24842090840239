import moment from "moment"
import { isMobile } from 'react-device-detect'

export const isInViewport = (elem) => {
  var bounding = elem.getBoundingClientRect()
  return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export const scrollToHotels = () => {
  let hotelEl = document.querySelector(".hotels")
  if (hotelEl) scrollToEl(hotelEl, "nearest")
}

export const scrollToOrderThankYou = () => {
  var orderThankYouEl = document.querySelector('.order-thank-you')
  if (orderThankYouEl) scrollToEl(orderThankYouEl, "start")
}

export const scrollToSubmitOrder = () => {
  var submitOrderEl = document.querySelector('.checkout-summary .actions')
  scrollToEl(submitOrderEl)
}

export const scrollToCheckout = () => {
  let scrollEl = document.querySelector(".checkout-page")
  if (scrollEl) scrollToEl(scrollEl, "start")
}

export const scrollToEcommerce = () => {
  let scrollEl = document.querySelector(".ecommerce-wrapper")
  if (scrollEl) scrollToEl(scrollEl, "start")
}

export const scrollToEl = (el, block = "end") => {
  if (el) {
    el.scrollIntoView({
      behavior: "smooth",
      block: block,
      inline: "nearest"
    })
  }
}

export const captializeFirst = (item) => {
  let lowercase = item.toLowerCase()
  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1)
}

export const earliestStartDate = () => {
  let today = new Date()
  let currentHoursInMDT = today.toLocaleTimeString('en-US', {timeZone: 'America/Denver', hour12: false }).split(':')[0]
  if (parseInt(currentHoursInMDT) >= 21) {
    return moment().hours(0).add(1, "days")
  } else {
    return moment().hours(0)
  }
}

export const isDateBlockedForSelection = (day) => day <= earliestStartDate()

// Convert moment date object to date object
export const momentObjectToDateObject = (date) => {
  let newDate = new Date(date)
  newDate.setHours(0,0,0,0)
  return newDate
}

export const isSmallScreen = () => isMobile || window.innerWidth < 765

export const formatCurrency = (amount, currency = 'USD', seperator =' ') => {
  return `${currency}${seperator}${Number.parseFloat(amount).toFixed(2)}`
}

export const setDelay = (timeout) => {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout)
  })
}

export const hideHotelsPageDetails = () => {
  let pageTitleContent = document.querySelector(".package-hotels.subpage .package-hero .container")
    pageTitleContent.style.display = "none"
}

export const showHotelsPageDetails = () => {
  let pageTitleContent = document.querySelector(".package-hotels.subpage .package-hero .container")
    pageTitleContent.style.display = "block"
}
