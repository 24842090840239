import { isIE, isEdge } from 'react-device-detect'
import moment from 'moment'

export const addIEClassToBody = () => {
  let body = document.querySelector("body")
  if (body && (isIE || isEdge) ) {
    body.classList.add(`ie ${isEdge ? 'edge': ''}`)
  }
}

export const prettifyDate = (date) => {
  return moment(date).format("MM/DD/YYYY")
}